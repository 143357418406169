<template>
    <div class="page1">
        <div class="mb20">
            链接地址： {{linkAddress}}
        </div>
        <el-button type="primary" @click="dialogVisible = true" size="small">增加</el-button>
        <!--     <EButton type="primary" @click="dialogVisible = true">增加</EButton>-->
        <ETable
                :tableTitle="tableTitle"
                :tableData="tableData"
                :needPagination="false"
        >
            <div slot="fileUrl" slot-scope="scope">
                <video
                        v-if="getType(scope.data.fileUrl)=='mp4'||getType(scope.data.fileUrl)=='mov'"
                        :src="scope.data.fileUrl" controls="controls" width="300px" >
                    您的浏览器不支持 video 标签。
                </video>
                <viewer :images="[scope.data.fileUrl]" v-else>
                    <img :src="scope.data.fileUrl" alt="" width="300px">
                </viewer>
            </div>
            <el-table-column fixed="right" label="操作" width="180px">
                <template slot-scope="scope">
                    <div>
                        <EButton type="text" @click="showDialog(scope.row)"> 修改
                        </EButton>
                        <EButton type="text" @click="remove(scope.row)"> 删除</EButton>
                    </div>
                </template>
            </el-table-column>
        </ETable>
        <EDialog
                :dialogVisible="dialogVisible"
                :title="dialogForm.itemId ? '编辑' : '添加'"
                @handleClose="cancelDialog"
                width="50%"
                @handleClick="saveData"
                :disabled="saveDisabled"

        >
            <el-row :gutter="10">
                <el-form size="small" label-position="'right'" label-width="120px" :model="dialogForm"
                         ref="form"
                >
                    <el-col :span="24">
                        <el-form-item label="说明：" prop="boothCode">
                            <el-input v-model="dialogForm.description" placeholder="说明"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="显示顺序：" prop="areas">
                            <el-input v-model="dialogForm.sortIndex" placeholder="显示顺序"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="图片/视频" prop="areaId">
                            （大小：100*100）
                            <mutilUpLoad :max="1" @getFile="getList" :fileTypes="['mov','png','jpg','jpeg','mp4','gif']"
                                     :initList="dialogForm.fileUrl?[dialogForm.fileUrl]:[]"></mutilUpLoad>

                        </el-form-item>
                    </el-col>


                </el-form>
            </el-row>
        </EDialog>
    </div>
</template>

<script>
  import ETable from '@/components/ETable';
  import mutilUpLoad from '@/components/mutilUpLoad';
  import Http from '@/service/http';
  import EDialog from '@/components/EDialog';
  import EButton from '@/components/EButton';
  import EForm from '@/components/EForm';
  import {identity_type, status} from '@/assets/js/config';
  import vxRule from '@/assets/js/formValidate';
  import {otherMixin} from '@/components/mixin';
  import {mapGetters} from 'vuex'
  const regex = /^[1-9]\d*$/
  export default {
    name: 'shopInfo',
    mixins: [otherMixin],
    data() {
      return {
        tableTitle: [
          {
            label: ' 图片/视频',
            prop: 'fileUrl',
            type:'slot'
          },
          {
            label: '说明',
            prop: 'description',
          },
          {
            label: '显示顺序',
            prop: 'sortIndex',
          },
          {
            label: '发布时间',
            prop: 'createTime',
          },
        ],
        tableData: [],
        dialogVisible: false,
        dialogForm: {
          description: '',
          fileUrl: '',
          screenConfigId:'',
          itemId:'',
          sortIndex:'999'
        },
        linkAddress: '',

      };
    },
    watch: {},
    created() {
      this.linkAddress = this.$route.query.linkAddress
      this.dialogForm.screenConfigId=this.$route.query.id
      this.getData();

    },
    components: {ETable, EDialog, EButton, EForm, mutilUpLoad},
    computed: {
      ...mapGetters(['buttonList']),
    },
    beforeMount() {
    },
    methods: {
      getType(file){
        let temp=file.split('/')
        let fileType=temp[temp.length-1]
        return fileType.split('.')[1]
      },
      getList(data) {
        console.log(data)
        this.dialogForm.fileUrl = data[0]
      },
      showDialog(data) {
        this.dialogForm = {
          description: data.description,
          fileUrl: data.fileUrl,
          itemId: data.itemId,
          screenConfigId:data.screenConfigId,
          sortIndex:data.sortIndex
        }
        this.dialogVisible=true
      },
      saveData(boothInformationId) {
        this.$refs['form'].validate((valid, object) => {
          if (valid) {
            this.saveItemFn();
          } else {
            return false;
          }
        });
      },
      async saveItemFn(){
        console.log(this.dialogForm.fileUrl)
        if(!this.dialogForm.sortIndex){
          this.$message.error('请输入显示顺序')
          return
        }else{
          if(!regex.test(this.dialogForm.sortIndex)){
            this.$message.error('请输入正整数')
            return
          }
        }
        if(!this.dialogForm.fileUrl){
          this.$message.error('请上传图片/视频')
          return
        }

        let res=await Http.saveItem(this.dialogForm)
        if(res.code==200){
          this.$message.success(res.msg)
          this.cancelDialog()
          this.getData()
        }
      },
      onSearch() {
        this.searchForm.current = 1;
        this.getData();
      },
      changePage(current) {
        this.searchForm.current = current;
        this.getData();
      },
      changeSize(size) {
        this.searchForm.size = size;
        this.getData();
      },
      cancelDialog() {
        this.dialogVisible = false;
        this.dialogForm = {
          description: '',
          fileUrl: '',
          screenConfigId:this.$route.query.id,
          itemId:'',
          sortIndex:this.dialogForm.sortIndex--,
        };
        this.$refs.form.resetFields();
      },

      async getData() {

        let res = await Http.cmbigscreenconfigItemList({
          screenConfigId:this.$route.query.id
        });
        if (res.code == 200) {
          this.tableData = res.data;
        }
      },
      remove(row) {
        this.$messageBox
            .confirm('确认删除?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
            .then(() => {
              this.deleteScreen(row.itemId);
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消删除',
              });
            });
      },
      async deleteScreen(screenConfigId) {
        let res = await Http.deleteScreen({id: screenConfigId});
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.getData();
        }
      },

    },
  };
</script>

<style lang="scss" scoped>
    .ws {
        width: 100%;
    }
</style>
