<template>
    <div class="up-load">
        <div class="upload-btn">
            <el-button size="small" type="primary" :disabled="urlList.length>=Number(max)"
                       v-if="!previewShow"
                       :loading="loading">点击上传
            </el-button>
            <el-button v-else
                       size="small"
                       :loading="loading"
                       type="primary"
                       :disabled="urlList.length>=Number(max)"
                       >点击上传
            </el-button>
            <input type="file" @change="getFile($event,'excel_path')" :multiple="multiple" ref="file"
                   :disabled="urlList.length>=Number(max)"/>
        </div>
        <ul class="file-list">
            <li v-for="(item,index) in urlList" :key="index"  >
                <video :src="urlList[index]"
                       v-if="getType(urlList[index])=='mp4'||getType(urlList[index])=='mov'" width="100px"
                       @click="preview(urlList[index])"/>
                <viewer :images="[urlList[index]]" v-else>
                    <img :src="urlList[index]" alt="" width="100px">
                </viewer>
<!--                <img :src="urlList[index]"-->
<!--                     alt=""-->
<!--                     width="100px"-->
<!--                     v-else-->
<!--                     @click="preview(urlList[index])"/>-->

                <i class="el-icon-error" @click="deleteImg(index,'excel_path')"></i>
            </li>
        </ul>
        <el-dialog :visible.sync="dialogVisible"
                   :fullscreen="true"
                   custom-class="preview-dialog"
        :modal="false">
            <video :src="previewUrl" width="60%" controls="controls" style="max-height: 600px;"></video>
        </el-dialog>
    </div>
</template>
<script>
  //oss 上传大文件
  const aliOss = require('ali-oss')
  let client = null
  let tempCheckpoint;
  import Http from "@/service/http";
  import {timestamp} from '@/assets/js/common'
  import md5 from 'js-md5';

  export default {
    props: {
      multiple: {
        type: Boolean,
        default: false
      },
      clear: {//清空数据
        type: Boolean,
        default: false
      },
      fileTypes: {
        type: Array,
        default: () => ['jpg', 'jpeg', 'png', 'bmp', 'docx', 'pdf', 'doc']
      },
      max: {//最多张数
        type: Number,
        default: 0
      },
      previewShow: {
        type: Boolean,
        default: false
      },
      initList: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        list: [],//展示的文件
        fileList: [], //上传的文件列表暂时存放，以防上传的次数不止一次
        loading: false,
        files: [],
        firstTime: true,
        previewUrl: '',//预览地址
        dialogVisible: false,
      }
    },
    computed: {
      urlList: {//上传的文件url列表
        get() {
          return this.initList
        },
        set(val) {
          return val
        },
      },

    },
    methods: {
      getType(file){
        console.log(this.urlList)
        if(file){
          let temp=file.split('/')
          let fileType=temp[temp.length-1]
          return fileType.split('.')[1]
        }

      },
      preview(url) {
        this.previewUrl = url
        this.dialogVisible = true
      },
      async getStsKey() {
        let res = await Http.getAccess()
        if (res.code == 200) {
          let data = res.data
          client = new aliOss({
            // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
            region: 'oss-cn-beijing',
            // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
            accessKeyId: data.credentials.accessKeyId,
            accessKeySecret: data.credentials.accessKeySecret,
            bucket: 'centra-market',
            stsToken: data.credentials.securityToken,
          });
          console.log(client)

        }
      },

      getFile(e) {
        let files = e.target.files
        if (this.list.length == 0) {
          this.firstTime = true
        } else {
          this.firstTime = false
        }
        for (let i = 0; i < files.length; i++) {
          let seat = ''
          let arr = files[i].name.split(".")
          seat = arr[arr.length - 1].toLowerCase();
          if (!this.fileTypes.includes(seat)) {
            this.$message.error(`${files[i].name}文件格式不支持,请重新上传`)
            continue;
          } else if (seat == 'jpg' || seat == 'jpeg' || seat == 'png' || seat == 'bmp') {
            if (files[i].size > 2097152) { //2M
              this.$message.error(`${files[i].name}图片大小超出2M,请重新上传`)
              continue;
            } else {
              this.list.push({files: files[i], loading: true})
              this.fileList.push({files: files[i], loading: true})
            }
          } else if (seat == 'doc' || seat == 'docx' || seat == 'pdf') {
            if (files[i].size > 104857600) { //100M
              this.$message.error(`${files[i].name}文件大小超出100M,请重新上传`)
              continue;
            } else {
              this.list.push({files: files[i], loading: true})
              this.fileList.push({files: files[i], loading: true})
            }
          } else {
            if (files[i].size > 1073741824) { //1G 视频
              this.$message.error(`${files[i].name}文件大小超出1G,请重新上传`)
              continue;
            } else {
              this.list.push({files: files[i], loading: true})
              this.fileList.push({files: files[i], loading: true})
            }

          }
          this.multipartUpload(e.target.files[i], i, files)
        }
        this.$refs.file.value = null//清空后可以重新上传同张文件


      },
      async multipartUpload(fileObj, i, files) {
        this.loading = true
        console.log(this.loading)
        let md5name = md5(timestamp())
        let type = fileObj.name.substring(fileObj.name.lastIndexOf("."));
        try {
          // object-key可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
          console.log(client)
          let result = await client.multipartUpload(`centra-market/public/${md5name}${type}`, fileObj, {
            progress: function (p, checkpoint) {
              // 断点记录点。浏览器重启后无法直接继续上传，您需要手动触发上传操作。
              tempCheckpoint = checkpoint;
            },
            meta: {year: 2020, people: 'test'},
            mime: 'image/jpeg'
          })
          const url = `https://centra-market.oss-cn-beijing.aliyuncs.com/centra-market/public/${md5name}${type}`
          console.log(url)
          this.fileList[i].loading = false
          this.loading = false
          console.log(this.loading)
          // if (this.firstTime) {
          //   this.list[i].loading = false
          // } else {
          //   this.list[this.list.length - (files.length - i)].loading = false
          // }
          this.urlList.push(url)
          // if (this.urlList.length == this.fileList.length) {
            this.$emit('getFile', this.urlList)
            this.$emit('getFileList', this.list)
          // }
        } catch (e) {
          console.log(e);
          this.list[i].loading = false
        }
      },
      // async getFile(event, flag) {//获取文件信息上传
      //   let files = event.target.files
      //
      //   if (this.list.length == 0) {
      //     this.firstTime = true
      //   } else {
      //     this.firstTime = false
      //   }
      //   for (let i = 0; i < files.length; i++) {
      //
      //     let seat = ''
      //     let arr = files[i].name.split(".")
      //     seat = arr[arr.length - 1].toLowerCase();
      //
      //     if (!this.fileTypes.includes(seat)) {
      //       this.$message.error(`${files[i].name}文件格式不支持,请重新上传`)
      //     } else if (seat == 'jpg' || seat == 'jpeg' || seat == 'png' || seat == 'bmp') {
      //       if (files[i].size > 2097152) { //2M
      //         this.$message.error(`${files[i].name}图片大小超出2M,请重新上传`)
      //       } else {
      //         this.list.push({files: files[i], loading: true})
      //         this.fileList.push({files: files[i], loading: true})
      //         // this.getUrl(files[i],i)
      //
      //       }
      //     } else if (seat == 'doc' || seat == 'docx' || seat == 'pdf') {
      //       if (files[i].size > 104857600) { //100M
      //         this.$message.error(`${files[i].name}文件大小超出100M,请重新上传`)
      //       } else {
      //         this.list.push({files: files[i], loading: true})
      //         this.fileList.push({files: files[i], loading: true})
      //         // this.getUrl(files[i],i)
      //       }
      //     } else {
      //       this.list.push({files: files[i], loading: true})
      //       this.fileList.push({files: files[i], loading: true})
      //       // this.getUrl(files[i],i)
      //     }
      //
      //   }
      //   this.getUrl(this.fileList)
      //   this.$refs.file.value = null//清空后可以重新上传同张文件
      //   this.$emit('getFile', this.urlList)
      //   this.$emit('getFileList', this.list)
      // },
      // async getUrl(files) {
      //   for (let i = 0; i < files.length; i++) {
      //     await uploadImage(files[i].files, 'image_content').then((res) => {
      //       if (res.flag === '1') {
      //         console.log(this.fileList)
      //         this.fileList[i].loading = false
      //         if (this.firstTime) {
      //           this.list[i].loading = false
      //         } else {
      //           this.list[this.list.length - (files.length - i)].loading = false
      //         }
      //         this.urlList.push(res.data.res)
      //       }
      //     })
      //   }
      //   this.fileList = []
      //
      // },
      deleteImg(idx) {
        this.list.splice(idx, 1)
        this.urlList.splice(idx, 1)
        this.$emit('getFile', this.urlList)
        this.$emit('getFileList', this.list)
      },
      clearFn() {
        this.fileList = []
        this.urlList = []
        this.list = []
        this.$emit('getFile', this.urlList)
        this.$emit('getFileList', this.list)
      },
    },

    watch: {
      clear: {
        // immediate: true, // 将立即以表达式的当前值触发回调
        handler(newVal) {
          if (newVal) {
            this.fileList = []
            this.urlList = []
            this.$emit('getFile', this.urlList)
            this.$emit('clearFn', false)
          }
        }
      },
    },
    created() {
      this.getStsKey()
    }
  }
</script>
<style lang="scss" scoped>

    .upload-btn {
        position: relative;

        input[type='file'] {
            display: block !important;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 99999;
            width: 80px;
            height: 32px;
            opacity: 0;
        }
    }

    .file-list {
        margin-top: 10px;
        padding-left: 0;

        &.preview {
            li {
                cursor: pointer;
                display: inline-block;
            }
        }

        li {
            display: inline-block;
            padding: 5px;
            border: 1px solid #f0f0f0;
            position: relative;
            .el-icon-error {
                color: red;
                position: absolute;
                right:0;
                top:0;
                font-size: 20px;
            }

            &:hover {
                background-color: #f0f0f0;
                color: #409EFF;
            }
        }

        &.bd {
            border: 1px solid #f0f0f0;
        }
    }

    .video-list {
        padding: 0 !important;
        display: flex;

        li {
            width: 200px;
            height: 120px;

            margin-right: 10px;
            text-align: center;
            position: relative;
            line-height: 120px;
            transition: all 1s;

            &:hover {
                div {
                    display: block;
                }

            }

            div {
                display: none;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 22;
                background: rgba(255, 255, 255, .1);
                font-size: 20px;

                .el-icon-video-play {
                    color: #fff;
                    padding: 10px;
                }

                .el-icon-delete {
                    color: #fff;
                    padding: 10px;
                }

            }

            video {
                width: 100%;
                height: 100%;

            }

        }

    }

</style>
